import { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { createSelector } from '@reduxjs/toolkit';

import { Schemas } from 'src/visualization/constants';
import type { DownholeCollectionType } from 'src/visualization/types';

import { RootState } from '../store';

const dataKey = 'data';
const getObjectByIdKey = 'getObjectById';

const gooseState = (state: RootState) => state.goose;

export const getDownholeCollections = createSelector(gooseState, (stateRoot) => {
    const downholeCollections: DownholeCollectionType[] = [];
    Object.entries(stateRoot.queries).forEach(([queryId, queryValue]: [string, any]) => {
        if (queryId.includes(getObjectByIdKey)) {
            const data = queryValue[dataKey];
            if (data?.object.schema.includes(Schemas.DownholeCollectionSchema)) {
                downholeCollections.push(data.object as DownholeCollectionType);
            }
        }
    });
    return downholeCollections;
});

// Must only be used when there is a guarantee that the data exist in RTK cache and is not gonna be deleted
export const getGooseObjectById = (objectId: string) =>
    createSelector(gooseState, (stateRoot) => {
        const gooseObject: GetObjectResponse | undefined = (
            Object.entries(stateRoot.queries).find(([queryId, queryValue]: [string, any]) => {
                const isValidQueryId = queryId.includes(getObjectByIdKey);
                const isValidQueryValue = queryValue[dataKey]?.object?.uuid === objectId;
                return isValidQueryId && isValidQueryValue;
            })?.[1] as any
        )?.[dataKey];

        return gooseObject;
    });
