import { OpenIcon, DriverIcon, ViewerIcon } from '@local/web-design-system/dist/icons';
import { ArrowUp, ArrowDown } from '@local/web-design-system/dist/icons/Arrows';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { OBJECT_OPEN_IN, ID_PARAM } from 'src/strings';
import { isSchemaViewable, isSchemaDriverViewable } from 'src/utils/extractSchema';
import { createNavigateToDriver, createNavigateToViewer } from 'src/utils/viewObjectUtils';

import { useStyles } from './ObjectViewOptionsMenu.styles';

interface ObjectViewOptionsMenuProps {
    objectSchema: string;
    variant?: 'light' | 'dark';
}

export function ObjectViewOptionsMenu({
    objectSchema,
    variant = 'light',
}: ObjectViewOptionsMenuProps) {
    const [id] = usePersistedState(ID_PARAM);
    const { classes } = useStyles({ variant });
    const navigate = useNavigate();

    const { hostname } = window.location;
    const params = useParams();
    const featureFlags = useFlags();

    const [popoverAnchor, setPopoverAnchor] = React.useState<Element | null>(null);
    const isOpen = Boolean(popoverAnchor);
    const Icon = isOpen ? ArrowUp : ArrowDown;

    const [menuWidth, setMenuWidth] = React.useState(0);
    const muiTheme = useTheme();

    const MENU_OPTIONS = [
        {
            key: 'Viewer',
            ItemComponent: ViewerIcon,
            action: createNavigateToViewer(navigate, params.hubCode, params.workspaceUuid, id),
            filterWith: isSchemaViewable,
        },
        {
            key: 'Driver',
            ItemComponent: DriverIcon,
            action: createNavigateToDriver(
                hostname,
                params.orgUuid,
                params.hubCode,
                params.workspaceUuid,
                id,
            ),
            filterWith: isSchemaDriverViewable,
        },
    ];

    const openMenu = (event: React.MouseEvent) => {
        setMenuWidth(event.currentTarget.clientWidth);
        setPopoverAnchor(event.currentTarget);
    };

    const closeMenu = React.useCallback(() => {
        setPopoverAnchor(null);
    }, []);

    const options = MENU_OPTIONS.filter((option) => option.filterWith(objectSchema, featureFlags));

    if (!options.length) {
        return null;
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <Button
                    onClick={openMenu}
                    className={classes.openInButton}
                    automation-id="open-in-plot-buttons-view"
                    size="large"
                    variant="contained"
                    fullWidth
                >
                    <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <OpenIcon className={classes.openInButtonOpenIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.listItem} variant="body2">
                                        {OBJECT_OPEN_IN}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Icon className={classes.openInButtonDownIcon} />
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
            <Menu
                id="view-object-options-menu"
                anchorEl={popoverAnchor}
                open={isOpen}
                onClose={closeMenu}
                className={classes.menu}
                MenuListProps={{
                    sx: {
                        width: menuWidth - 2, // 2px is the border width
                        border: `1px solid ${muiTheme.palette.grey[500]}`,
                    },
                }}
            >
                {options.map((option) => {
                    const { key, ItemComponent, action } = option;
                    return (
                        <MenuItem key={key} onClick={action} className={classes.menuItem}>
                            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                                <Grid item>
                                    <ItemComponent />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.listItem} variant="body2">
                                        {key}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
